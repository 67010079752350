import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/(app)": [8,[2],[3]],
		"/(app)/(public)/_test-scrollable": [12,[2],[3]],
		"/(app)/(public)/about": [~13,[2],[3]],
		"/(app)/(public)/articles": [~14,[2],[3]],
		"/(app)/(public)/articles/server-side": [~16,[2],[3]],
		"/(app)/(public)/articles/[id]": [~15,[2],[3]],
		"/(app)/(auth0)/auth0-account": [~9,[2,4],[3]],
		"/(api)/auth0": [6],
		"/(api)/auth0/sign-in/redirect": [~7],
		"/(app)/(public)/auth": [17,[2],[3]],
		"/(app)/(public)/auth/account": [~18,[2],[3]],
		"/(app)/(public)/auth/email-verification": [~19,[2],[3]],
		"/(app)/(public)/auth/no-email": [~20,[2],[3]],
		"/(app)/(public)/auth/password-reset": [~21,[2],[3]],
		"/(app)/(public)/auth/password-reset/[token]": [~22,[2],[3]],
		"/(app)/(public)/auth/sign-in": [~23,[2],[3]],
		"/(app)/(public)/auth/sign-up": [~24,[2],[3]],
		"/(app)/(public)/hello-world": [~25,[2],[3]],
		"/(app)/(auth0)/hello-world/auth0": [~10,[2,4],[3]],
		"/(app)/(lucia)/hello-world/lucia": [~11,[2,5],[3]],
		"/(app)/(public)/settings": [~26,[2],[3]],
		"/(app)/(public)/todos": [~27,[2],[3]],
		"/(app)/(public)/todos/cancelled": [29,[2],[3]],
		"/(app)/(public)/todos/done": [30,[2],[3]],
		"/(app)/(public)/todos/server-side": [~31,[2],[3]],
		"/(app)/(public)/todos/[id]": [~28,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';
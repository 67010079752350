import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://2cc106e841f0419298aedcba47a03ade@o4504536230068224.ingest.us.sentry.io/4504932870979584',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
})

export const handleError = Sentry.handleErrorWithSentry()
